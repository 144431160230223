import React from 'react';

const Footer = () => {

    const imgBasePath = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_CDN_BASEURL : '';

    return (
        <footer style={{
            backgroundImage: `url('${imgBasePath}/assets/paper.jpg')`
        }}>
            <div>
                <small>
                    &copy; 2021 Fare x Bene Onlus | <a href="//www.iubenda.com/privacy-policy/532474" target="_blank" rel="noopener noreferrer" title="Privacy Policy">Privacy Policy</a>
                </small>
                <small>
                    Design: <a href="https://www.leoburnett.it" target="_blank" rel="noopener noreferrer">Leo Burnett</a> - Development: <a href="https://www.arcww.it" target="_blank" rel="noopener noreferrer">Arc</a>
                </small>
            </div>
        </footer>
    );
};

export default Footer;
