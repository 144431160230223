import React from 'react';
import PropTypes from 'prop-types';
import Popin from "../Popin/Popin";

const FormErrors = (props) => {

    const {
        errors,
        onCloseBtnClick
    } = props;

    return (
        <Popin onCloseBtnClick={onCloseBtnClick} className={`errors-popin`}>
            <>
                <p>
                    Verifica le seguenti informazioni:
                </p>
                <ul>
                    {errors.map((error, i) => {
                        return (
                            <li key={i}>{error.msg}</li>
                        )
                    })}
                </ul>
                <p>
                    I dati raccolti saranno usati in <strong>forma anonima</strong>, ma ci aiuteranno ad analizzare meglio il fenomeno per poterlo contrastare al meglio.
                </p>
            </>
        </Popin>
    );
}

FormErrors.propTypes = {
    errors: PropTypes.array.isRequired,
    onCloseBtnClick: PropTypes.func.isRequired
}

export default FormErrors;
