import React from 'react';

import logo from "../../../img/farexbene.svg";

const Logo = ({width, height}) => {
    return (
        <img
            width={width || 109}
            height={height || 120}
            src={logo}
            alt="Fare x Bene Onlus"
        />
    );
};


export default Logo;
