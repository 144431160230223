import React, {useState} from 'react';
import PropTypes from 'prop-types';

import OutsideClickHandler from "react-outside-click-handler";
import {Picker} from "emoji-mart";

import 'emoji-mart/css/emoji-mart.css'

const EmojiPicker = (props) => {

    const {
        onEmojiSelect
    } = props;

    const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

    const hideEmojiPicker = () => {
        setIsEmojiPickerVisible(false);
    }
    const toggleEmojiPicker = () => {
        setIsEmojiPickerVisible(!isEmojiPickerVisible);
    }

    return (

        <div>
            <button
                className="emoji-btn"
                title="Clicca qui per inserire una emoji"
                onClick={toggleEmojiPicker}>
                🤬
            </button>
            {isEmojiPickerVisible === true ?
                <div className="emoji-picker">
                    <OutsideClickHandler
                        onOutsideClick={hideEmojiPicker}
                    >
                        <Picker
                            native
                            onSelect={onEmojiSelect}
                            showPreview={false}
                            useButton={false}
                            title={''}
                            theme={'dark'}
                            exclude={[ 'recent', 'custom' ]}
                            i18n={{
                                search: 'Cerca',
                                clear: 'Cancella',
                                notfound: 'Nessuna Emoji Trovata',
                                skintext: 'Scegli il colore della pelle predefinito',
                                categories: {
                                    search: 'Risultati di ricerca',
                                },
                                categorieslabel: 'Categorie',
                            }}

                        />
                    </OutsideClickHandler>
                </div> : <></>
            }
        </div>

    );

};

EmojiPicker.propTypes = {
    onEmojiSelect: PropTypes.func.isRequired
};

export default EmojiPicker;