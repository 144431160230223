import React from 'react';

import PropTypes from 'prop-types';
import Popin from "../Popin/Popin";

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    TelegramIcon
} from "react-share";

const SharePopin = (props) => {

    const {
        onCloseBtnClick
    } = props;

    const shareUrl = `https://farexbene.arcww.tech`;
    const iconBorderRadius = 10;
    const iconRound = false;
    const iconSize = 48;

    const shareTitle = 'IN ITALIA IL CYBERBULLISMO È UNA TORTURA PER 1 RAGAZZO SU 3';
    const shareDescription = 'Puoi combatterlo. Vai sulla nostra pagina e condividi l’insulto che hai subito: i dati che raccoglieremo ci aiuteranno a far conoscere il problema.';
    const shareHashTags = ['cyberbullismo', 'cybertortura', 'farexbene'];

    return (
        <Popin onCloseBtnClick={onCloseBtnClick} className="share-popin">
            <>
                <h2>Questo progetto ha bisogno di te. <strong>Condividilo</strong> e aiutaci a far conoscere il problema.</h2>
                <div className="share-icons">

                    <FacebookShareButton
                        url={shareUrl}
                        quote={shareTitle+'\n\n'+shareDescription+'\n\n'+shareUrl+'\n\n#'+shareHashTags.join(" #")}
                    >
                        <FacebookIcon round={iconRound} borderRadius={iconBorderRadius} size={iconSize}/>
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={shareUrl}
                        title={shareTitle+'\n\n'+shareDescription+'\n'}
                        hashtags={shareHashTags}
                    >
                        <TwitterIcon round={iconRound} borderRadius={iconBorderRadius} size={iconSize}/>
                    </TwitterShareButton>

                    <WhatsappShareButton
                        url={shareUrl}
                        title={shareTitle+'\n\n'+shareDescription+'\n'}
                    >
                        <WhatsappIcon round={iconRound} borderRadius={iconBorderRadius} size={iconSize}/>
                    </WhatsappShareButton>

                    <LinkedinShareButton
                        url={shareUrl}
                        title={shareTitle}
                        summary={shareDescription}
                    >
                        <LinkedinIcon round={iconRound} borderRadius={iconBorderRadius} size={iconSize}/>
                    </LinkedinShareButton>

                    <TelegramShareButton
                        url={shareUrl}
                        title={shareTitle+'\n\n'+shareDescription+'\n\n'+shareUrl+'\n\n#'+shareHashTags.join(" #")}
                    >
                        <TelegramIcon round={iconRound} borderRadius={iconBorderRadius} size={iconSize}/>
                    </TelegramShareButton>

                    <EmailShareButton
                        url={shareUrl}
                        subject={shareTitle}
                        body={shareTitle+'\n\n'+shareDescription+'\n\n'+shareUrl+'\n\n#'+shareHashTags.join(" #")}
                    >
                        <EmailIcon round={iconRound} borderRadius={iconBorderRadius} size={iconSize}/>
                    </EmailShareButton>


                </div>
            </>
        </Popin>
    );

}

SharePopin.propTypes = {
    onCloseBtnClick: PropTypes.func.isRequired
}

export default SharePopin;