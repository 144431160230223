import React from 'react';
import PropTypes from 'prop-types';




const SelectFormField = (props) => {

    const {
        name,
        value,
        label,
        options,
        onChange,
        errors,
        children
    } = props;

    return (
        <div style={{'position': 'relative'}} className={ errors.findIndex(e => e.field === name) !== -1 ? 'with-error' : '' } >
            {(options && options.length) ?
                <>
                    <select
                        id={`field-${name}`}
                        onChange={onChange}
                        placeholder={label}
                        className={ value !== '' ? 'filled' : 'blank' }
                    >
                        <option value="">{label}</option>
                        {options.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
                    </select>
                    { children }
                    <label htmlFor={`field-${name}`}>{label}</label>
                </> : <></>
            }
        </div>
    );

}

SelectFormField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired,
    children: PropTypes.element
}


export default SelectFormField;