import React, {useState} from 'react';
import SharePopin from "../SharePopin/SharePopin";

const ShareIcon = () => {

    const [isOpen, setIsOpen] = useState(false);

    const onClick = (e) => {
        e.preventDefault();
        setIsOpen(true);
    }

    const onCloseBtnClick = () => {
        setIsOpen(false);
    }

    return (
        <>
            <button
                className="share-icon"
                onClick={onClick}
                title="Questo progetto ha bisogno di te. Condividilo e aiutaci a far conoscere il problema.
"
            >
                <div>
                    <span>Questo progetto ha bisogno di te.<br/>Condividilo e aiutaci a far conoscere il problema.
    </span>
                    <svg width="200px" height="200px" viewBox="0 0 200 200">
                        <path d="M170,104.75a10,10,0,0,0-10,10v22.5a20.06,20.06,0,0,1-20,20H60a20.06,20.06,0,0,1-20-20v-70a10,10,0,0,1,10-10H74.5a10,10,0,0,0,0-20H50a30.09,30.09,0,0,0-30,30v70a40.12,40.12,0,0,0,40,40h80a40.12,40.12,0,0,0,40-40v-22.5A10,10,0,0,0,170,104.75Z"/>
                        <path d="M97.5,137.25a10,10,0,0,0,10-10V89.75a20.06,20.06,0,0,1,20-20H148l-12,12a9.9,9.9,0,0,0,14,14l21-21a19.74,19.74,0,0,0,6-14v-1a3.75,3.75,0,0,0-.5-2.5,18,18,0,0,0-5.5-10.5l-21-21a9.67,9.67,0,0,0-14,0,9.67,9.67,0,0,0,0,14l10.5,10.5h-19a40.12,40.12,0,0,0-40,40v37A10,10,0,0,0,97.5,137.25Z"/>
                    </svg>
                </div>

            </button>
            { isOpen === true && <SharePopin onCloseBtnClick={onCloseBtnClick}/> }
        </>
    )
}

export default ShareIcon;