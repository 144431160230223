import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './Balloon.scss';
import {ReactComponent as TickIcon} from './tick.svg';
import nl2br from 'nl2br';
import striptags from 'striptags';

const Balloon = (props) => {

    const {
        text,
        color,
        appear,
        delay,
        x,
        y,
        zIndex,
        playSound,
        isWallPaused
    } = props;

    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        window.setTimeout(()=>{
            setIsVisible(true);
            // console.log(sound);
            if(playSound && !isWallPaused) {
                playSound()
            }
        }, delay);
        // eslint-disable-next-line
    }, []);

    if(!isVisible) {
        return null;
    }

    const styles = {
        left: `${x}%`,
        top: `${y}%`
    };
    if(zIndex) styles.zIndex = zIndex;

    return (
        <div
            className={`balloon-wrapper`}
            style={styles}
        >
            <div
                className={`balloon balloon-${color} ${appear && ` balloon-appear`}`}
            >
                <p dangerouslySetInnerHTML={{
                    __html: nl2br(striptags(text))
                }}/>
                <TickIcon/>
            </div>
        </div>
    );
};

Balloon.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    appear: PropTypes.bool,
    delay: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
    zIndex: PropTypes.number,
    isWallPaused: PropTypes.bool
};

export default Balloon;
