import React, {useState, useEffect, useLayoutEffect} from 'react';
// import ReactScrollDetect, { DetectSection } from 'react-scroll-detect';
// import CONST from "../config/CONST";

import {
    ScrollingProvider,
    useScrollSection,
    Section,
} from 'react-scroll-section';


import postsApi from "./api/postsApi";

import Home from "./components/Sections/Home/Home";
import Form from "./components/Sections/Form/Form";
import Wall from "./components/Sections/Wall/Wall";
import WhoWeAre from "./components/Sections/WhoWeAre/WhoWeAre";
import NeedHelp from "./components/NeedHelp/NeedHelp";
// import statsApi from "./api/statsApi";
// import Stats from "./components/Sections/Stats/Stats";

import './App.scss';import ShareIcon from "./components/ShareIcon/ShareIcon";


const imgBasePath = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_CDN_BASEURL : '';
const r = Math.floor(Math.random() * (3 - 1) + 1);
const homeImgSrc = `${imgBasePath}/assets/hp-${r}.png`;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const App = (props) => {

    const {
        isMobile
    } = props;

    // eslint-disable-next-line
    const [windowWidth, windowHeight] = useWindowSize();

    const [newPost, setNewPost] = useState({});
    const [posts, setPosts] = useState([]);
    const [isWallPaused, setIsWallPaused] = useState(false);
    const [isWallFinished, setIsWallFinished] = useState(false);
    const [currentWallPage, setCurrentWallPage] = useState(0);

    const [currentSection, setCurrentSection] = useState('home');


    // const [stats, setStats] = useState(null);
    // async function getStats () {
    //     const _stats = await statsApi.get();
    //     setStats(_stats);
    // }

    async function getPosts (page) {
        return await postsApi.get(page);
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);
    }

    const onPostAdded = (post) => {
        // setNewPost(post);
        setNewPost({});
    }

    const refreshWall = async (page, force) => {
        if(force === true || (!isWallPaused && !isWallFinished)) {
            const newPosts = await getPosts(page);
            if(newPosts && newPosts.length) {
                newPosts.forEach((p, i) => {
                    newPosts[i].color = getRandomInt(1,2) % 2 ? 'blue' : 'green';
                    newPosts[i].x = getRandomInt(10,90);
                    newPosts[i].y = getRandomInt(10,90);
                })
                await setPosts(posts.concat(newPosts)) ; //posts.concat(newPosts));
                await setCurrentWallPage(page + 1);
            }
            else {
                setIsWallFinished(true);
            }
        }
    }

    useEffect(() => {
        if(currentWallPage && !isWallFinished) {
            return window.setTimeout(() => {
                refreshWall(currentWallPage);
            }, 3000);
        }
        // eslint-disable-next-line
    }, [currentWallPage]);

    useEffect(() => {
        if(isMobile) {
            const bodyClass = isMobile.phone ? 'phone' : isMobile.tablet ? 'tablet' : 'desktop';
            document.body.classList.add(bodyClass);
        }
    }, [isMobile]);



    const onSectionChange = (sectionId) => {

        if(currentSection !== sectionId) {

            setCurrentSection(sectionId);

            switch (sectionId) {

                case 'wall':
                    setIsWallPaused(false);
                    refreshWall(currentWallPage, true);
                    break;

                // case 'stats':
                //     setIsWallPaused(true);
                //     getStats();
                //     break;

                default:
                    setIsWallPaused(true);
                    break;

            }
        }



    }






    return (
        <ScrollingProvider>


            <div
                id="app"
                className={ isMobile.phone ? 'phone' : isMobile.tablet ? 'tablet' : 'desktop' }
                style={{ 'height': `${windowHeight}px`}}
            >

                <AppSections
                    isMobile={isMobile}
                    onPostAdded={onPostAdded}
                    posts={posts}
                    newPost={newPost}
                    isWallPaused={isWallPaused}
                    windowHeight={windowHeight}
                    onSectionChange={onSectionChange}
                    // stats={stats}
                />

                <NeedHelp/>
                <ShareIcon/>

            </div>
        </ScrollingProvider>
    );

}




const AppSections = (props) => {

    const {
        isMobile,
        onPostAdded,
        posts,
        newPost,
        isWallPaused,
        windowHeight,
        onSectionChange
        // stats
    } = props;

    const homeSection = useScrollSection('home');
    const formSection = useScrollSection('form');
    const wallSection = useScrollSection('wall');
    // const statsSection = useScrollSection('stats');
    const whoWeAreSection = useScrollSection('who-we-are');

    if(homeSection.selected) {
        onSectionChange("home");
    }
    if(formSection.selected) {
        onSectionChange("form");
    }
    if(wallSection.selected) {
        onSectionChange("wall");
    }
    // if(statsSection.selected) {
    //     onSectionChange("stats");
    // }
    if(whoWeAreSection.selected) {
        onSectionChange("who-we-are");
    }



    return (
        <>
            <Section id="home" style={{ minHeight: `${windowHeight}px`}}>
                <Home
                    isMobile={isMobile}
                    windowHeight={windowHeight}
                    imgSrc={homeImgSrc}
                />
            </Section>

            <Section id="form" style={{ minHeight: `${windowHeight}px`}}>
                <Form
                    isMobile={isMobile}
                    onPostAdded={onPostAdded}
                    windowHeight={windowHeight}
                />
            </Section>

            <Section id="wall" style={{ minHeight: `${windowHeight}px`}}>
                <Wall
                    isMobile={isMobile}
                    newPost={newPost}
                    posts={posts}
                    isWallPaused={isWallPaused}
                    style={{ minHeight: `${windowHeight}px`}}
                    windowHeight={windowHeight}
                />
            </Section>

            {/*
            <Section id="stats" style={{ minHeight: `${windowHeight}px`}}>
                <Stats
                    isMobile={isMobile}
                    stats={stats}
                    windowHeight={windowHeight}
                />
            </Section>
            */}

            <Section id="who-we-are" style={{ minHeight: `${windowHeight}px`}}>
                <WhoWeAre
                    isMobile={isMobile}
                    windowHeight={windowHeight}
                />
            </Section>

        </>
    )
}

export default App;
