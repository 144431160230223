import React, {useState} from 'react';
import DonationPopin from "../DonationPopin/DonationPopin";

const InfoBoxes = () => {

    const [isDonationPopinOpen, setIsDonationPopinOpen] = useState(false);

    const openDonationPopin = () => {
        setIsDonationPopinOpen(true);
    }
    const closeDonationPopin = () => {
        setIsDonationPopinOpen(false);
    }


    return (
        <div className="info-boxes">
            <div>
                <a className="box" href="http://www.farexbene.it/wxabapb/contatti/" target="_blank" rel="noopener noreferrer">
                    {/*<Logo />*/}
                    Hai bisogno di aiuto? Contattaci
                </a>
            </div>
            <div>
                <button
                    className="box" onClick={openDonationPopin}>
                    Abbiamo bisogno di te.<br/>
                    Aiutaci con una donazione o con il 5x1000.<br/>
                    Grazie.
                </button>
            </div>

            { isDonationPopinOpen === true && <DonationPopin onCloseBtnClick={closeDonationPopin}/>}

        </div>
    );
};

export default InfoBoxes;
