import React from 'react';
import PropTypes from 'prop-types';
import Popin from "../Popin/Popin";

const DonationPopin = (props) => {

    const {
        onCloseBtnClick
    } = props;

    return (
        <Popin onCloseBtnClick={onCloseBtnClick} className="donation-popin">
            <>
                <h3>Sostieni Fare x Bene</h3>
                <p>
                    Per sostenere Fare x Bene in favore delle attività e dei progetti di  assistenza alle vittime di
                    discriminazioni, abusi e violenza e dei progetti di prevenzione ed educazione alle differenze di
                    genere nelle scuole secondarie di primo e secondo grado potete donare tramite 3 modalità differenti:
                </p>

                <ul>
                    <li>
                        bonifico bancario all'IBAN <strong>IT57F0538701665000042432060</strong><br/>
                        Conto "BPER Banca"<br/>
                        intestato a "<strong>FARE X BENE Onlus</strong>"
                    </li>
                    <li>
                        destinando il vostro 5x1000 indicando sulla vostra dichiarazione il Codice Fiscale <strong>97551880152</strong>
                    </li>
                    <li>
                        cliccando sul pulsante “<strong>Fai una donazione</strong>" sulla nostra <a href="https://www.facebook.com/FARE-X-BENE-ONLUS-299016810285219" target="_blank" rel="noopener noreferrer">pagina FaceBook</a>
                    </li>
                </ul>
            </>
        </Popin>
    );

};

DonationPopin.propTypes = {
    onCloseBtnClick: PropTypes.func.isRequired
}

export default DonationPopin;