import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

const Campaigns = () => {

    const [zoom, setZoom] = useState(null);
    const [zoomVisible, setZoomVisible] = useState(false);

    const zoomIn = (e) => {
        const img = e.target.getAttribute('src');
        const width = e.target.width;
        const height = e.target.height;
        setZoom({
            img,
            width,
            height
        });
        setZoomVisible(true);
        // console.log({
        //     img,
        //     width,
        //     height
        // });
    }

    const zoomOut = () => {
        setZoom(null);
        setZoomVisible(false);
    }

    const imgBasePath = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_CDN_BASEURL : '';


    return (
        <>

            <div id="zoom"/>

            <div className="campaigns">

                <div>
                    <div className="col">
                        <div className="img">
                            <img onClick={zoomIn} src={`${imgBasePath}/assets/campaigns/03.jpg`} alt=""/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="img">
                            <img onClick={zoomIn} src={`${imgBasePath}/assets/campaigns/01.jpg`} alt=""/>
                            <img onClick={zoomIn} src={`${imgBasePath}/assets/campaigns/02.jpg`} alt=""/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="img">
                            <img onClick={zoomIn} src={`${imgBasePath}/assets/campaigns/04.jpg`} alt=""/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="img">
                            <img onClick={zoomIn} src={`${imgBasePath}/assets/campaigns/05.jpg`} alt=""/>
                        </div>
                    </div>
                </div>

                <Zoom>
                    <CSSTransition
                        classNames="zoomtrans"
                        in={zoomVisible}
                        timeout={200}
                    >
                        <>
                            {zoom &&
                                <div className="zoom" onClick={zoomOut}>
                                    <img
                                        className={zoom.width > zoom.height ? `horizontal` : `vertical`}
                                        src={zoom.img}
                                        alt=""
                                    />
                                </div>
                            }
                        </>
                    </CSSTransition>
                </Zoom>

            </div>


        </>
    );
};


const Zoom = ({children}) => {
    const mountPoint = document.getElementById('zoom');
    if(!mountPoint) {
        return null;
    }
    return ReactDOM.createPortal(children, mountPoint);
}

export default Campaigns;
