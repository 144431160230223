import React from 'react';
import Logo from "../../Logo/Logo";
import {useScrollSection} from "react-scroll-section";

const imgBasePath = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_CDN_BASEURL : '';

const Home = (props) => {

    const {
        windowHeight,
        imgSrc
    } = props;

    const formSection = useScrollSection('form');

    const scrollToForm = (e) => {
        //e.preventDefault();
        formSection.onClick();
    }



    return (
        <div className="section home" style={{
            backgroundImage: `url('${imgBasePath}/assets/paper.jpg')`,
            minHeight: `${windowHeight}px`
        }}>

            <div>

                <div className="home-logo">
                    <Logo/>
                </div>

                <h1>In Italia <br className="xs" /> il cyberbullismo <br/> è una tortura <br className="xs" /> per <strong>1</strong> ragazzo su <strong>3</strong></h1>

                <div className="home-img">
                    <HomeIllustration imgSrc={imgSrc} />
                </div>

                <p className="txt">
                    E la pandemia ha peggiorato il fenomeno.<br/>Aiutaci a combattere ogni forma di discriminazione e violenza online.<br/>
                    <a href="#form" onClick={scrollToForm}><strong>Raccontaci la tua esperienza</strong></a>, con l’opzione di restare completamente anonimo.<br/>
                    Se sei stato/a vittima di cyberbullismo, scrivi gli insulti che hai ricevuto: ci aiuterai a raccogliere dati e a far conoscere il problema.
                </p>

                {/*<small className="footnote">* {!isMobile.any ? <a title="www.generazioniconnesse.it" href="https://www.generazioniconnesse.it/" target="_blank" rel="noopener noreferrer">Generazioni Connesse</a> : <>Generazioni Connesse</>} per il Safer Internet Day 2021</small>*/}

            </div>

        </div>
    );
};


Home.propTypes = { };

export default Home;



const HomeIllustration = (props) => {

    const {
        imgSrc
    } = props;

    return (
        <img
            width={420}
            height={293}
            src={imgSrc}
            alt=""
        />
    );
}
