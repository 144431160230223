import axios from 'axios'
import CONST from "../../config/CONST";


const postsApi = {

    post: async function (payload) {

        const url = `${CONST.API.BASEURL}${CONST.API.ROUTES.POSTS}`;

        try {
            const response = await axios.post(url, {
                ...payload
            }, {

            });
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    },

    get: async function (page) {
        const limit = 10;
        const url = `${CONST.API.BASEURL}${CONST.API.ROUTES.POSTS}?_sort=createdAt:DESC&_start=${page*limit}&_limit=${limit}`;
        try {
            const response = await axios.get(url, { });
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
};

export default postsApi;
