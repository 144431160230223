import React from 'react';
import PropTypes from 'prop-types';
import Popin from "../Popin/Popin";

const TopicsHelp = (props) => {

    const {
        topics,
        onCloseBtnClick
    } = props;

    return (
        <Popin onCloseBtnClick={onCloseBtnClick} className="topics-popin">
            <>
                {topics.map(topic => {
                    return (
                        <React.Fragment key={`topic-${topic.id}`}>
                            <h3>{topic.name}</h3>
                            <p>{topic.description}</p>
                        </React.Fragment>
                    )
                })}
            </>
        </Popin>
    );

};

TopicsHelp.propTypes = {
    topics: PropTypes.array.isRequired,
    onCloseBtnClick: PropTypes.func.isRequired
}

export default TopicsHelp;