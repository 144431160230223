import React, {useEffect} from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';

const Popin = (props) => {

    const {
        children,
        onCloseBtnClick,
        className
    } = props;

    useEffect(() => {

        document.body.classList.add("popin-open");

        return function cleanup() {
            document.body.classList.remove("popin-open");
        };

    });


    return (
        <PopinPortal>
            <div className={`popin-wrapper ${ className ? className : ``}`}>
                <button className="close" onClick={onCloseBtnClick}>x</button>
                <div className="popin-body">
                    {children}
                </div>
            </div>
        </PopinPortal>
    );
};

Popin.propTypes = {
    onCloseBtnClick: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    className: PropTypes.string
};

export default Popin;



const PopinPortal = (props) => {
    return ReactDOM.createPortal(props.children, document.getElementById('fxb-popin'));
}