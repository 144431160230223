import React from 'react';
import Logo from "../Logo/Logo";

const NeedHelp = () => {
    return (
        <a
            className="need-help"
            href="http://www.farexbene.it"
            title="www.farexbene.it"
            target="_blank"
            rel="noopener noreferrer"
        >
            <span>Hai bisogno di supporto?</span>
            <div><Logo width={45} height={49}/></div>
        </a>
    );
};


export default NeedHelp;
