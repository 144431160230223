import React, {useState} from 'react';

import Campaigns from "../../Campaigns/Campaigns";
import Footer from "../../Footer/Footer";
import FarexBeneOnlus from "../../FarexBeneOnlus/FarexBeneOnlus";
import InfoBoxes from "../../InfoBoxes/InfoBoxes";
import SharePopin from "../../SharePopin/SharePopin";

const imgBasePath = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_CDN_BASEURL : '';

const WhoWeAre = (props) => {

    const {
        windowHeight
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const onCloseBtnClick = () => {
        setIsOpen(false);
    }

    return (
        <div className="section who-we-are" style={{
            backgroundImage: `url('${imgBasePath}/assets/paper.jpg')`,
            minHeight: `${windowHeight}px`
        }}>

            <div>
                <div className="content">
                    <FarexBeneOnlus/>
                </div>
                <div>
                    <InfoBoxes/>
                </div>
                <div>
                    <Campaigns/>
                </div>

                <div style={{textAlign: 'center'}}>
                    <button className="share-button" onClick={()=>{setIsOpen(true)}}>Questo progetto ha bisogno di te.<br/>Condividilo e aiutaci a far conoscere il problema.</button>
                    { isOpen === true && <SharePopin onCloseBtnClick={onCloseBtnClick}/> }
                </div>

            </div>

            <Footer/>

        </div>
    );
};


export default WhoWeAre;
