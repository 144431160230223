import React from 'react';
import Logo from "../Logo/Logo";

const FarexBeneOnlus = () => {
    return (
        <>
            <div className="img">
                <Logo/>
            </div>
            <div className="txt">
                <p>
                    <strong><a href="http://www.farexbene.it" target="_blank" rel="noopener noreferrer">Fare x Bene Onlus</a></strong> è un'associazione che sostiene promuove e tutela i diritti inviolabili della persona,
                    soprattutto delle categorie sociali più deboli e soggette a discriminazioni come donne, bambini e
                    persone disabili. Si impegna in attività a sostegno e creazione di progetti di accoglienza, assistenza
                    legale e psicologica nei confronti delle vittime di discriminazioni, abusi e violenze e di
                    sensibilizzazione, prevenzione ed educazione alle differenze di genere, discriminazioni, bullismo e
                    cyberbullismo.
                </p>
            </div>
        </>
    );
};

export default FarexBeneOnlus;
