const CONST = {
    ENV: process.env.REACT_APP_VERCEL_ENV || process.env.NODE_ENV, // see https://vercel.com/docs/environment-variables#system-environment-variables
    // FULLPAGE_LICENSE_KEY: '794A336D-F089449C-BB38FEA5-E7CC639D',
    API: {
        BASEURL: process.env.REACT_APP_API_BASEURL,
        ROUTES: {
            POSTS: '/posts',
            REGIONS: '/regions',
            DISTRICTS: '/districts',
            GENDERS: '/genders',
            TOPICS: '/topics',
            NATIONALITIES: '/nationalities',
            SCHOOLS: '/schools',
            FREQUENCIES: '/frequencies',
            STATS: '/stats'
        }
    }
};

export default CONST;