import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import postsApi from "../../../api/postsApi";
import gendersApi from "../../../api/gendersApi";
import districtsApi from "../../../api/districtsApi";
import topicsApi from "../../../api/topicsApi";
import nationalitiesApi from "../../../api/nationalitiesApi";
import frequenciesApi from "../../../api/frequenciesApi";
import schoolsApi from "../../../api/schoolsApi";
import TopicsHelp from "../../TopicsHelp/TopicsHelp";
import FormErrors from "../../FormErrors/FormErrors";
import SelectFormField from "../../SelectFormField/SelectFormField";
import InputFormField from "../../InputFormField/InputFormField";
import EmojiPicker from "../../EmojiPicker/EmojiPicker";
import ThankYouMessage from "../../ThankYouMessage/ThankYouMessage";
// import {useScrollSection} from "react-scroll-section";

const imgBasePath = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_CDN_BASEURL : '';

const Form = (props) => {

    const {
        windowHeight,
        isMobile,
        onPostAdded
    } = props;

    // const wallSection = useScrollSection('form');

    const [school, setSchool] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [district, setDistrict] = useState('');
    const [nationality, setNationality] = useState('');
    const [topic, setTopic] = useState('');
    const [bullyNationality, setBullyNationality] = useState('');
    const [bullyGender, setBullyGender] = useState('');
    const [bullyAge, setBullyAge] = useState('');
    const [bullySchool, setBullySchool] = useState('');
    const [askedForHelp, setAskedForHelp] = useState('');
    const [helpAskedFrom, setHelpAskedFrom] = useState('');
    const [helpWasHelpful, setHelpWasHelpful] = useState('');
    const [frequency, setFrequency] = useState('');

    const [availableGenders, setAvailableGenders] = useState(null);
    const [availableTopics, setAvailableTopics] = useState(null);
    const [availableDistricts, setAvailableDistricts] = useState(null);
    const [availableSchools, setAvailableSchools] = useState(null);
    const [availableNationalities, setAvailableNationalities] = useState(null);
    const [availableFrequencies, setAvailableFrequencies] = useState(null);

    const [errors, setErrors] = useState([]);
    const [showErrors, setShowErrors] = useState(true);

    const [topicsPopupVisible, setTopicsPopupVisible] = useState(false);


    const [balloonVisible, setBalloonIsVisible] = useState(true);
    const [showThankyouMsg, setShowThankyouMsg] = useState(false);

    const balloon = useRef();

    const validate = (payload) => {
        const _errors = [];
        if(!payload.school || payload.school === '' || !availableSchools.find(s => s.id === payload.school)) {
            _errors.push({ field: 'school', msg: 'Seleziona la tua scuola' });
        }
        if(!payload.age || payload.age === '' || !payload.age.match(/^[0-9]+$/) || parseInt(payload.age) < 4 ||  parseInt(payload.age) > 99) {
            _errors.push({ field: 'age', msg: 'Seleziona un\'età valida' });
        }
        if(!payload.gender || payload.gender === '' || !availableGenders.find(g => g.id === payload.gender)) {
            _errors.push({ field: 'gender', msg: 'Seleziona il tuo genere' });
        }
        if(!payload.nationality || payload.nationality === '' || !availableNationalities.find(n => n.id === payload.nationality)) {
            _errors.push({ field: 'nationality', msg: 'Seleziona la tua nazionalità' });
        }
        if(!payload.district || payload.district === '' || !availableDistricts.find(d => d.id === payload.district)) {
            _errors.push({ field: 'district', msg: 'Seleziona la tua provincia' });
        }
        if(!payload.topic || payload.topic === '' || !availableTopics.find(t => t.id === payload.topic)) {
            _errors.push({ field: 'topic', msg: 'Seleziona la tipologia di insulto' });
        }
        if(!payload.bully_nationality || payload.bully_nationality === '' || !availableNationalities.find(n => n.id === payload.bully_nationality)) {
            _errors.push({ field: 'bully-nationality', msg: 'Seleziona la nazionalità del carnefice' });
        }
        if(!payload.bully_school || payload.bully_school === '' || !availableSchools.find(s => s.id === payload.bully_school)) {
            _errors.push({ field: 'bully-school', msg: 'Seleziona la scuola del carnefice' });
        }
        if(!payload.bully_age || payload.bully_age === '' || !payload.bully_age.match(/^[0-9]+$/) || parseInt(payload.bully_age) < 4 ||  parseInt(payload.bully_age) > 99) {
            _errors.push({ field: 'bully-age', msg: 'Seleziona un\'età del carnefice valida' });
        }
        if(!payload.frequency || payload.frequency === '' || !availableFrequencies.find(f => f.id === payload.frequency)) {
            _errors.push({ field: 'frequency', msg: 'Seleziona la frequenza dell\'episodio' });
        }
        if(!payload.text || payload.text.length < 2) {
            _errors.push({ field: 'text', msg: 'Scrivi l\'insulto che hai ricevuto' });
        }
        return _errors;
    }

    const generatePayload = () => {
        return {
            name: striptags(name) || null,
            text: striptags(text) || '',
            age: striptags(age) || null,
            gender: striptags(gender) || null,
            school: striptags(school) || null,
            nationality: striptags(nationality) || null,
            district: striptags(district) || null,
            topic: striptags(topic) || null,
            bully_age: striptags(bullyAge) || null,
            bully_gender: striptags(bullyGender) || null,
            bully_school: striptags(bullySchool) || null,
            bully_nationality: striptags(bullyNationality) || null,
            asked_for_help: striptags(askedForHelp) || null,
            help_asked_from: striptags(helpAskedFrom) || null,
            help_was_helpful: striptags(helpWasHelpful) || null,
            frequency: striptags(frequency) || null
        };
    }

    useEffect(() => {

        async function getDistricts () {
            setAvailableDistricts(await districtsApi.get());
        }
        getDistricts();

        async function getTopics () {
            setAvailableTopics(await topicsApi.get());
        }
        getTopics();

        async function getGenders () {
            setAvailableGenders(await gendersApi.get());
        }
        getGenders();

        async function getNationalities () {
            setAvailableNationalities(await nationalitiesApi.get());
        }
        getNationalities();

        async function getFrequencies () {
            setAvailableFrequencies(await frequenciesApi.get());
        }
        getFrequencies();

        async function getSchools () {
            setAvailableSchools(await schoolsApi.get());
        }
        getSchools();


    }, []);

    const onSubmitBtnClick = () => {
        setErrors([]);
        const payload = generatePayload();
        const _errors = validate(payload);
        if(_errors.length === 0) {
            postComment(payload)
        }
        else {
            setErrors(_errors);
            setShowErrors(true);
        }
    }

    const postComment = (payload) => {

        // hide balloon
        setBalloonIsVisible(false);

        // save post to backEnd
        postsApi.post(payload);
        onPostAdded(payload);

        // thank you message
        setShowThankyouMsg(true);

        // scroll to wall
        // scrollToWall();

    }
    
    // const scrollToWall = (e) => {
    //     e.preventDefault();
    //     wallSection.onClick();
    // }

    const handleText = (e) => {
        setText(e.target.value);
    }
    const handleName = (e) => {
        setName(e.target.value);
    }
    const handleGender = (e) => {
        setGender(e.target.value);
    }
    const handleAge = (e) => {
        setAge(e.target.value);
    }
    const handleNationality = (e) => {
        setNationality(e.target.value);
    }
    const handleDistrict = (e) => {
        setDistrict(e.target.value);
    }
    const handleTopic = (e) => {
        setTopic(e.target.value);
    }
    const handleBullyNationality = (e) => {
        setBullyNationality(e.target.value);
    }
    const handleBullyGender = (e) => {
        setBullyGender(e.target.value);
    }
    const handleSchool = (e) => {
        setSchool(e.target.value);
    }
    const handleBullySchool = (e) => {
        setBullySchool(e.target.value);
    }
    const handleBullyAge = (e) => {
        setBullyAge(e.target.value);
    }
    const handleAskedForHelp = (e) => {
        setAskedForHelp(e.target.value);
    }
    const handleHelpAskedFrom = (e) => {
        setHelpAskedFrom(e.target.value);
    }
    const handleHelpWasHelpful = (e) => {
        setHelpWasHelpful(e.target.value);
    }
    const handleFrequency = (e) => {
        setFrequency(e.target.value);
    }

    const onEmojiSelect = (emoji) => {
        setText(text + emoji.native);
    }


    return (
        <div className="section formSection" style={{
            backgroundImage: `url('${imgBasePath}/assets/paper.jpg')`,
            minHeight: `${windowHeight}px`
        }}>

            { showThankyouMsg === true && <ThankYouMessage/> }

            <div className={`form ${showThankyouMsg === true ? `hidden` : `visible`}`}>

                <div className="form-intro">
                    <p>Aiutaci a combattere ogni forma di discriminazione e violenza online.</p>
                    <p><strong>Raccontaci la tua esperienza.</strong></p>
                </div>

                <div className="col">

                    <InputFormField name="name" value={name} label="Nome (opzionale)" onChange={handleName} errors={errors} />

                    <div className="row">
                        <SelectFormField name="school" value={school} label="Scuola" options={availableSchools || []} onChange={handleSchool} errors={errors} />
                        <InputFormField name="age" value={age} label="La tua età" onChange={handleAge} errors={errors} type="number" min={1} max={40} />
                    </div>
                    <div className="row">
                        <SelectFormField name="nationality" value={nationality} label="Nazionalità" options={availableNationalities || []} onChange={handleNationality} errors={errors}/>
                        <SelectFormField name="gender" value={gender} label="Genere" options={availableGenders || []} onChange={handleGender} errors={errors}/>
                    </div>
                    <SelectFormField name="district" value={district} label="Provincia" options={availableDistricts || []} onChange={handleDistrict} errors={errors}/>
                    <SelectFormField name="topic" value={topic} label="Tipologia di insulto" options={availableTopics || []} onChange={handleTopic} errors={errors}>
                        <button className="topics-help" onClick={() => setTopicsPopupVisible(true)}>?</button>
                    </SelectFormField>

                    <div className="spacer"/>

                    <div className="txt">
                        <p><strong>Descrizione del carnefice</strong></p>
                    </div>
                    <div className="row">
                        <SelectFormField name="bully-school" value={bullySchool} label="Scuola" options={availableSchools || []} onChange={handleBullySchool} errors={errors} />
                        <InputFormField name="bully-age" value={bullyAge} label="Età" onChange={handleBullyAge} errors={errors} type="number" min={1} max={40} />
                    </div>
                    <div className="row">
                        <SelectFormField name="bully-nationality" value={bullyNationality} label="Nazionalità" options={availableNationalities || []} onChange={handleBullyNationality} errors={errors}/>
                        <SelectFormField name="bully-gender" value={bullyGender} label="Genere" options={availableGenders || []} onChange={handleBullyGender} errors={errors}/>
                    </div>

                    <div className="spacer"/>

                    <SelectFormField name="frequency" value={frequency} label="È stato un episodio" options={availableFrequencies || []} onChange={handleFrequency} errors={errors}/>
                    <SelectFormField name="asked-for-help" value={askedForHelp} label="Hai chiesto aiuto?" options={[
                        { id: '1', name: 'Sì' },
                        { id: '0', name: 'No' },
                    ]} onChange={handleAskedForHelp} errors={errors}/>

                    <div className={ `field-toggle ` + (askedForHelp !== "1" ? `field-hidden` : `field-visible`) }>
                        <InputFormField name="help-asked-from" value={helpAskedFrom} label="A chi hai chiesto aiuto?" onChange={handleHelpAskedFrom} errors={errors}/>
                        <SelectFormField name="help-was-helpful" value={helpWasHelpful} label="L'aiuto è servito?" options={[
                            { id: '1', name: 'Sì' },
                            { id: '0', name: 'No' },
                        ]} onChange={handleHelpWasHelpful} errors={errors}/>
                    </div>
                </div>

                <div className="col">

                    <div className="txt">
                        <p><strong>Scrivi l’insulto che hai ricevuto</strong></p>
                    </div>

                    <div ref={balloon}>
                        <div className={`balloon ${!balloonVisible && `balloon-disappear`}`}>
                            <label htmlFor="field-txt">Messaggio</label>
                            <textarea
                                id="field-txt"
                                value={text}
                                maxLength="1000"
                                onChange={handleText}
                            />
                        </div>
                        { !isMobile.any && <EmojiPicker onEmojiSelect={onEmojiSelect} /> }
                    </div>

                    <button className="form-btn" title="Condividi la tua storia" onClick={onSubmitBtnClick}>Invia</button>

                    {errors && errors.length > 0 && showErrors === true
                        ? <FormErrors errors={errors} onCloseBtnClick={() => setShowErrors(false) } />
                        : <></>
                    }

                    { topicsPopupVisible === true && availableTopics && availableTopics.length
                        ? <TopicsHelp topics={availableTopics} onCloseBtnClick={() => setTopicsPopupVisible(false) } />
                        : <></>
                    }

                </div>
            </div>

        </div>
    );
};

Form.propTypes = {
    isMobile: PropTypes.object.isRequired,
    onPostAdded: PropTypes.func.isRequired
};

export default Form;

