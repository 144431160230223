import axios from 'axios'
import CONST from "../../config/CONST";

const frequenciesApi = {
    get: async function () {
        const url = `${CONST.API.BASEURL}${CONST.API.ROUTES.FREQUENCIES}?_sort=sort:ASC`;
        try {
            const response = await axios.get(url, { });
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
};

export default frequenciesApi;
