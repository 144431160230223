import React, {useEffect} from 'react';
import Logo from "../Logo/Logo";
import useSound from "use-sound";
import sound from "../Sections/Wall/notification.mp3";

const ThankYouMessage = () => {

    const [playSound] = useSound(sound, {
        "volume": 0.4
    });

    useEffect(()=>{
        window.setTimeout(playSound, 150);
    }, [playSound]);

    return (
        <>
            <div className="thankyou">
                <div className="thankyou-appear">
                    <div className="thankyou-logo">
                        <Logo/>
                    </div>
                </div>
                <div className="thankyou-appear">
                    <div className="balloon balloon-fuchsia">
                        <div>
                            <p>
                                <strong>Grazie per aver condiviso la tua storia</strong>
                            </p>
                            <p>
                                Questa pagina è un posto sicuro per tutte le vittime di cyberbullismo, per questo la
                                pubblicazione dei messaggi non avviene in maniera automatica: vedrai comparire il tuo
                                messaggio non appena concluso il percorso di approvazione.
                            </p>
                            <p>
                                La tua esperienza ci permetterà di accrescere le nostre conoscenze sulle modalità
                                utilizzate da giovani e giovanissimi. Avremo modo di aiutare tuoi coetanei a non subire
                                la tua stessa tortura chi ancora non percepisce di essere vittima e sottovaluta la
                                situazione.<br/>
                                Se hai bisogno di aiuto <a href="http://www.farexbene.it/wxabapb/contatti/" rel="noopener noreferrer">contattaci</a>!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default ThankYouMessage;