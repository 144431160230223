import React from 'react';
import PropTypes from 'prop-types';

const InputFormField = (props) => {

    const {
        name,
        value,
        label,
        onChange,
        errors,
        type,
        min,
        max
    } = props;

    return (
        <div className={ errors.findIndex(e => e.field === name) !== -1 ? 'with-error' : '' } >
            <input
                id={`field-${name}`}
                className={ value !== '' ? 'filled' : 'blank' }
                type={ type || "text" }
                min={ min || null}
                max={ max || null}
                onChange={onChange}
                placeholder={label}
            />
            <label htmlFor={`field-${name}`}>{label}</label>
        </div>
    );

}

InputFormField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired,
    type: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
}

export default InputFormField;