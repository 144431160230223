import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Balloon from "../../Balloon/Balloon";
import useSound from 'use-sound';
import sound from './notification.mp3';

const Wall = (props) => {

    const {
        windowHeight,
        newPost,
        posts,
        isWallPaused
    } = props;

    const [playSound] = useSound(sound, {
        "volume": 0.4
    });
    const newPostDelay = 1500;
    const wallPostsDelay = 750;

    useEffect(()=>{
        if(newPost && newPost.text) {
            window.setTimeout(playSound, newPostDelay);
        }
    }, [newPost, playSound]);

    const imgBasePath = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_CDN_BASEURL : '';

    return (
        <div className="section wall" style={{
            backgroundImage: `url('${imgBasePath}/assets/paper.jpg')`,
            height: `${windowHeight}px`
        }}>

            <div className="wall-inner" style={{
                backgroundImage: `url('${imgBasePath}/assets/wall-bg.jpg')`,
            }}>

                { (newPost && newPost.text) &&
                    <Balloon
                        text={newPost.text}
                        color={'blue'}
                        appear={true}
                        x={50}
                        y={50}
                        delay={newPostDelay}
                        zIndex={999}
                        isWallPaused={false}
                    />
                }

                { (posts && posts.length) ?
                    <>
                        {posts.map( (p, i) => {
                            return (
                                <Balloon
                                    key={p.id}
                                    text={p.text}
                                    color={p.color}
                                    appear={true}
                                    x={p.x}
                                    y={p.y}
                                    delay={ (i * wallPostsDelay) + ((newPost && newPost.text) ? newPostDelay + wallPostsDelay : 0) }
                                    playSound={false}
                                    isWallPaused={isWallPaused}
                                />
                            )
                        })}
                    </> : <></>
                }

            </div>

            {/*<div className="wall-caption">*/}
            {/*    <h2>Il cyberbullismo è una tortura per <strong>2</strong> ragazzi italiani su <strong>3</strong></h2>*/}
            {/*    <p>*/}
            {/*        Durante la pandemia gli episodi sono aumentati del 59%. Aiutaci a combattere ogni forma di discriminazione e violenza online.*/}
            {/*        Scopri come su <a href="http://www.farexbene.it" target="_blank" rel="noopener noreferrer">farexbene.it</a>*/}
            {/*    </p>*/}
            {/*</div>*/}

        </div>
    );
};

Wall.propTypes = {
    newPost: PropTypes.object,
    posts: PropTypes.array.isRequired,
    isWallPaused: PropTypes.bool.isRequired
};

export default Wall;
