import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';


import isMobile from "ismobilejs";

const consoleText = () => {
    if(console && console.log && typeof console.log === 'function') {
        console.clear();
    }
    if(console && console.log && typeof console.log === 'function') {
        console.log("");
        console.log("%cIn Italia il cyberbullismo è una tortura per 1 ragazzo su 3", "background-color: #DE007B; color: #ffffff; font-size: 14px; font-weight: bold; padding: 10px");
        console.log("");
        console.log("%cE la pandemia ha peggiorato il fenomeno.", "color: #DE007B; font-size: 12px; font-weight: bold");
        console.log("%cAiutaci a combattere ogni forma di discriminazione e violenza online.", "color: #DE007B; font-size: 12px; font-weight: bold");
        console.log("%cRaccontaci la tua storia, con l’opzione di restare completamente anonimo.", "color: #DE007B; font-size: 12px; font-weight: bold");
        console.log("%cSe sei stato/a vittima di cyberbullismo, scrivi gli insulti che hai ricevuto: ci aiuterai a raccogliere dati e a far conoscere il problema.", "color: #DE007B; font-size: 12px; font-weight: bold");
        console.log("");
        console.log("%c Fare x Bene Onlus ", "background-color: #DE007B; color: #FFFFFF; font-size: 12px; font-weight: bold");
        console.log("%cwww.farexbene.it", "font-size: 12px; font-weight: bold");
        console.log("");
        console.log("%c Ideazione: Leo Burnett ", "background-color: #00a050; color: #FFFFFF; font-size: 12px; font-weight: bold");
        console.log("%cwww.leoburnett.it", "color: #00a050; font-size: 12px; font-weight: bold");
        console.log("");
        console.log("%c Sviluppo: Arc Leo Burnett ", "background-color: #f7cd45; color: #000000; font-size: 12px; font-weight: bold");
        console.log("%cwww.arcww.it", "font-size: 12px; font-weight: bold");
        console.log("");
        console.log("");
    }
}
consoleText();


ReactDOM.render(
  <React.StrictMode>
    <App isMobile={isMobile(window.navigator)} />
  </React.StrictMode>,
  document.getElementById('fxb')
);